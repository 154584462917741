import React from 'react';
import winery from '../assets/winery.jpg'
import { motion } from "framer-motion";

import { IoIosArrowDown } from "react-icons/io";
import {BsArrowRightShort  } from "react-icons/bs";
import integrations from '../assets/g74.png'


function IntegrationsHero() {
    return (
        <div className = "heroIntegrationsSection ">
            < motion.div initial={{ opacity: 0,y:70 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2.4, bounce: 0.6
            
        }} className = "integrationsImage" style={{backgroundImage: `url(${integrations})`}} >
            
          <motion.div initial={{ opacity: 0.5,y:40 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.4
            
        }} className = "heroIntegrationsText">
<h1 style = {{textAlign:"center"}}>Integrations & Automation</h1>
<p className = "noMT textCenter">Thank's to Commerce7's robust Open API, we can connect a variety of tools; whether its for syncing data, automating boring tasks, or anything in between.</p>
<a href = "mailto:beattynathan@icloud.com" className = "CTAbtn blueButton">Let's Talk</a>
</motion.div>


        

        </motion.div>


</div>




    );
}

export default IntegrationsHero;