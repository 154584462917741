import React from 'react';
import winery from '../assets/zapierlogo.jpeg'
import install from '../assets/addapp.png'
import accept from '../assets/accept.png'
import zapdash from '../assets/zapdash.png'
import newzapchoose from '../assets/newzapchoose.png'
import tenant from '../assets/tenant.png'
import { motion } from "framer-motion";

import { IoIosArrowDown } from "react-icons/io";
import {BsArrowRightShort  } from "react-icons/bs";
import {TbArrowDown, TbArrowRight} from "react-icons/tb";
function Zapdoctext() {
    return (
        <div className = "docBody">
        <motion.div className = "docSection" initial={{ opacity: 0,y:50 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.5
            
        }} >
            <img className = "docLogoImg" src = {winery}/>
<h1>Automation with Zapier Docs</h1>
<h3 className = "subheader">How to get setup and running with our automation app.</h3>
<div className = "docStep">
    <h2>Step 1: Install the App in C7</h2>
    <img src = {install} className = "docScreenshot shadow"/>
</div>
<div>
<TbArrowDown size = {25}/>
</div>

<div className = "docStep">
    <h2>Step 2: Click Our Invite Link & Join</h2>
    <a href="https://zapier.com/developer/public-invite/179113/4d5ff4f86505e68233ed56d8b30d292f/" className = "roundedBtn blueButton">Join in Zapier</a>
          

</div>
<div>
<TbArrowDown size = {25}/>
</div>
<div className = "docStep">
    <h2>Step 3: Accept the Invite and Setup Your First Zap</h2>
    <p>After clicking on the accept invite and build a zap link, you can either sign up for a zapier account or log in to your existing account. From there you'll be able to get started with using C7 in your zap automations.</p>
    <img src = {accept} className = "docScreenshot shadow"/>
    <img src = {zapdash} className = "docScreenshot shadow"/>

    
    <p>From here you can click create to setup your first Zap. </p>
    <img src = {newzapchoose} className = "docScreenshot shadow"/>
    <p>Choose the Dionysus C7 Integration.</p>
    <img src = {tenant} className = "docScreenshot shadow"/>
    <p>You'll need to enter your tenantId. This can either be found when downloading the app, or in Commerce7 by going to Settings > General.</p>

    <h4>For more information on how you create automations with Zapier, please check out their documentation <a href = "https://zapier.com/learn/zapier-quick-start-guide">here,</a> or reach out to us and we'll be happy to help you. </h4>
</div>



        
        </motion.div></div>



    );
}

export default Zapdoctext;