import React from 'react';
import winery from '../assets/pexelredblue.jpg'
import collage from '../assets/winecollage3.png'
import { motion } from "framer-motion";
import {TbArrowDown, TbArrowRight} from "react-icons/tb";
import { IoIosArrowDown } from "react-icons/io";
import {BsArrowRightShort  } from "react-icons/bs";
function CTA() {
    return (
        <div className = "mt80 bgImage" style={{backgroundImage: `url(${collage})`}} >
        <div className = "CTASection">

        <motion.div initial={{ opacity: 0,y:50 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.5
            
        }} className = "CTAText">
            <h6 style = {{color:"rgb(0, 119, 255)"}} className = "mbNO">Contact Us</h6>
        <h2 className = "noMT">Have an idea for work to be done?</h2>
        <p className = "noMT">Our flexible and customizable approach means we can find solutions for whatever problems you're looking to solve.</p>
        <a href = "mailto:beattynathan@icloud.com" className = "CTAbtn blueButton">Let's Talk <TbArrowRight/></a>
        </motion.div>
        
        </div>


</div>
    );
}

export default CTA;