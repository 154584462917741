import React from 'react';
import winery from '../assets/winery.jpg'
import { motion } from "framer-motion";

import { IoIosArrowDown } from "react-icons/io";
import {BsCheckCircle, BsCloudCheck, BsCodeSlash, BsMouse  } from "react-icons/bs";
import integrations from '../assets/biggerapps.png'
import googlesheets from '../assets/googlesheets.png'
import twilio from '../assets/twilio.png'
import slack from '../assets/slacklogo.png'
import hubspot from '../assets/hubspot.jpeg'
import flodesk from '../assets/flodesk.png'

function IntegrationsWhat() {
    return (
        <div>
        <div className = "whatIntegrationsSection">
           
           
           <h1 className = "whatinthead">Example Use Cases</h1>
           <p className = "pwhattext noMT">To give you a better idea of how we can help, here are some example use cases that you could get started with. Zapier has over 5000+ out of the box integrations available, so the sky is the limit.</p>
           </div>

            
<div>
<div className = "autoAppGrid">
            <div className = "autoApp grow">
                
                <div className = "appImgBox">
                <img className = "appImg" src = {googlesheets}></img>
                </div>
                <div className = "appTextBox">
<h5 className = "appText">Update records seamlessly in a google sheet.</h5>
                
                </div>
            </div>


            <div className = "autoApp grow">
                
                <div className = "appImgBox">
                <img className = "appImg" src = {twilio}></img>
                </div>
                <div className = "appTextBox">
<h5 className = "appText">Text a customer a confirmation message for an order or reservation.</h5>
                
                </div>
            </div>

            <div className = "autoApp grow">
                
                <div className = "appImgBox">
                <img className = "appImg" src = {slack}></img>
                </div>
                <div className = "appTextBox">
<h5 className = "appText">Send your team a slack message when something happens in Commerce7.</h5>
                
                </div>
            </div>

            <div className = "autoApp grow">
                
                <div className = "appImgBox">
                <img className = "appImg" src = {hubspot}></img>
                </div>
                <div className = "appTextBox">
<h5 className = "appText">Sync customer records between Commerce7 and Hubspot.</h5>
                
                </div>
            </div>

            <div className = "autoApp grow">
                
                <div className = "appImgBox">
                <img className = "appImg" src = {flodesk}></img>
                </div>
                <div className = "appTextBox">
<h5 className = "appText">Add new customers to email campaigns automatically.</h5>
                
                </div>
            </div>

           
           </div>     

            


</div>
</div>




    );
}

export default IntegrationsWhat;