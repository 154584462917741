import React from 'react';
import winery from '../assets/winery.jpg'
import { motion } from "framer-motion";
import data from '../assets/girlwine2.png'
import winehero from '../assets/willy4.png'
import { IoIosArrowDown } from "react-icons/io";
import {TbArrowDown, TbArrowRight} from "react-icons/tb";

function ReportHero() {
    return (
        
< motion.div initial={{ opacity: 0,y:70 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.55
            
        }} className = "herobgImage noMT" 
        // style={{backgroundImage: `url(${winery})`}} 
        >

<div className = "noMT heroFeatureSectionflip ">

<motion.div initial={{ opacity: 0,y:70 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2.4, bounce: 0.5
            
        }} className = "featureLeft">
<h1>Your Data, Your Way</h1>
<p>Understanding your data is critical in making better decisions for your winery. We help you understand your data from Commerce7 to drive success.</p>
<div>

</div>
</motion.div>
<motion.div initial={{ opacity: 0,y:70 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.5
            
        }}  className = "featureRight">  
<div className = "featureImg450" style={{backgroundImage: `url(${winehero})`}}></div>
</motion.div>

</div>

</motion.div>



    );
}

export default ReportHero;