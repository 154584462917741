import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import logo from '../assets/logo.svg';

function Footer() {
  return (
    <div className = "footerBar noMT">

       
          <p className = "copyrightText">Dionysus Analytics 2023 ©</p>
      

          
        
    </div>
  );
}

export default Footer;