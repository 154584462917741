import React from 'react';
import Navbar from '../components/navbar';
import Hero2 from '../components/hero2';
import Features from '../components/features';
import Footer from '../components/footer';
import CTA from '../components/CTA';
import Philosophy from '../components/philosophy';
import {useEffect} from 'react';
import Zapdoctext from '../components/zapdoctext';

function Zapdocs() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0});
      }, []);



    return (
        <div>
        <Navbar/>
        <Zapdoctext/>
        
        
        <Footer/>
        </div>
    );
}

export default Zapdocs;