import React from 'react';
import winery from '../assets/valleyWine.jpg'
import yellow from '../assets/g71.png'
import wine from '../assets/appity2.png'
import grapes from '../assets/grapes.jpg'
import {TbArrowDown, TbArrowRight} from "react-icons/tb";
import winehero from '../assets/willy4.png'
import { IoArrowForward, IoIosArrowDown } from "react-icons/io";
import {BsArrowRightShort  } from "react-icons/bs";
import { BrowserRouter, Route, Link } from "react-router-dom";


function Features() {
    return (
        <div>
        <div className = "featureSectionflip">

        <div className = "featureLeft">
        <h2>Commerce7 Apps</h2>
        <p>Our Commerce7 apps cover a range of applications and business use cases to make sure you maximize the Commerce7 platform.</p>
        <div>
        <Link className="btn blackButton" to="/apps">
        See All Apps <TbArrowRight/>
        </Link>
        </div>
        </div>
        <div className = "featureRight">  
        <div className = "featureImg" style={{backgroundImage: `url(${wine})`}}></div>
        </div>
        
        </div>
        <div className = "featureSection">

        <div className = "featureLeft">
        <h2>Integrations & Automation</h2>
        <p>Does your team have a tool they use daily that isn't currently supported within Commerce7? We help bridge the gap by connecting business tools, syncing data, and helping streamline your operation.</p>
        <div>
        <Link className="btn blackButton" to="/integrations">
        Integrations <TbArrowRight/>
        </Link>
        
        </div>
        </div>
        <div className = "featureRight">  
        <div className = "featureImg" style={{backgroundImage: `url(${yellow})`}}></div>
        </div>
        
        </div>
        <div className = "featureSectionflip">

        <div className = "featureLeft">
        <h2>Custom Reporting</h2>
        <p>Our custom reports deliver key metrics and valuable insights for optimizing operations and driving success. By leveraging our expertise in custom reporting, we can help you make data-driven decisions that elevate your winery to new heights.</p>
        <div>
        <Link className="btn blackButton" to="/reports">
        Reports <TbArrowRight/>
        </Link>
        </div>
        </div>
        <div className = "featureRight">  
        <div className = "featureImg" style={{backgroundImage: `url(${winehero})`}}></div>
        </div>
        
        </div>
        
        </div>
    );
}

export default Features;