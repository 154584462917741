import React from 'react';
import winery from '../assets/winery.jpg'
import { motion } from "framer-motion";
import data from '../assets/girlwine2.png'
import winehero from '../assets/willy4.png'
import { IoIosArrowDown } from "react-icons/io";
import {TbArrowDown, TbArrowRight} from "react-icons/tb";
import {BsCheckCircle, BsCloudCheck, BsCodeSlash, BsMouse  } from "react-icons/bs";
function ReportFeature() {
    return (
        
< motion.div initial={{ opacity: 0,y:70 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.55
            
        }} className = "herobgImage noMT" 
        // style={{backgroundImage: `url(${winery})`}} 
        >

<div className = "noMT heroFeatureSection ">

<motion.div initial={{ opacity: 0,y:70 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2.4, bounce: 0.5
            
        }} className = "featureLeft">
<h2>Reporting shouldn't be hard</h2>
<p>Get the key insights you need to better understand your ecommerce operations. We can build reports across any combination of data points, and help display it in a way for you to make decisions.</p>

<div className = "optionsGrid">
    <div className = "optionItem"><BsCheckCircle/><h5 className = "mtmbNO pl10">KPI's</h5></div>
    <div className = "optionItem"><BsCheckCircle/><h5 className = "mtmbNO pl10">Location Data</h5></div>
    <div className = "optionItem"><BsCheckCircle/><h5 className = "mtmbNO pl10">Emails</h5></div>
    <div className = "optionItem"><BsCheckCircle/><h5 className = "pl10 mtmbNO">Wine Club</h5></div>
    <div className = "optionItem"><BsCheckCircle/><h5 className = "pl10 mtmbNO">Purchase History</h5></div>
    <div className = "optionItem"><BsCheckCircle/><h5 className = "pl10 mtmbNO">Tasks</h5></div>
    <div className = "optionItem"><BsCheckCircle/><h5 className = "pl10 mtmbNO">Customer Info</h5></div>
    <div className = "optionItem"><BsCheckCircle/><h5 className = "pl10 mtmbNO">Reservations</h5></div>
    <div className = "optionItemNOBORDER">
    <h5 className = "mtmbNO">and more...</h5>
    </div>
</div>

</motion.div>
<motion.div initial={{ opacity: 0,y:70 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.5
            
        }}  className = "featureRight">  
<div className = "featureImg" style={{backgroundImage: `url(${data})`}}></div>
</motion.div>

</div>

</motion.div>



    );
}

export default ReportFeature;