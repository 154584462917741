import React from 'react';
import Navbar from '../components/navbar';
import IntegrationsHero from '../components/integrationsHero';
import IntegrationsHow from '../components/integrationsHow';
import IntegrationsWhat from '../components/integrationsWhat';
import Footer from '../components/footer';
import AppCTA from '../components/appCTA'
import {useEffect} from 'react';
function Integrations() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0});
      }, []);


    return (
        <div>
        <Navbar/>
        <IntegrationsHero/>
        <IntegrationsHow/>
        <IntegrationsWhat/>
        <AppCTA/>
        <Footer/>
        </div>
    );
}

export default Integrations;