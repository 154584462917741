import React from 'react';
import winery from '../assets/winery.jpg'
import { motion } from "framer-motion";
import {BsCheckCircle, BsCloudCheck, BsCodeSlash, BsMouse  } from "react-icons/bs";
import data from '../assets/girlwine2.png'
import winehero from '../assets/appity2.png'
// import winehero from '../assets/wineapps2.png'

import { IoIosArrowDown } from "react-icons/io";
import {TbArrowDown, TbArrowRight} from "react-icons/tb";
function AppsHero() {
    return (
     <div>
        < motion.div initial={{ opacity: 0,y:70 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.55
            
        }} className = "herobgImage noMT c7bg" 
        // style={{backgroundImage: `url(${winery})`}} 
        >

<div className = "noMT heroFeatureSection ">

<motion.div initial={{ opacity: 0,y:70 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2.4, bounce: 0.5
            
        }} className = "featureLeft">
<h1 className = "">Commerce7 Apps</h1>
<p className = "">Apps built to integrate directly into Commerce7 and help take your work to the next level.</p>
<motion.div className = "alignSideDivLeft noMT">
<h5 className = "">Explore Our Apps</h5><TbArrowDown color=""/>

</motion.div>

<div>
</div>
</motion.div>
<motion.div initial={{ opacity: 0,y:70 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.5
            
        }}  className = "featureRight">  
<div className = "featureImg300wide " style={{backgroundImage: `url(${winehero})`}}></div>
</motion.div>

</div>

</motion.div>


</div>

    );
}

export default AppsHero;