import React from 'react';
import winery from '../assets/winery.jpg'
import { motion } from "framer-motion";
import excel from '../assets/excel.png'
import googlesheets from '../assets/googlesheets.png'
import smartsheet from '../assets/smartsheet.png'
import jotform from '../assets/jotform.png'

import zoho from '../assets/zoho.png'
import tableau from '../assets/tableaulogo.svg'
import powerbi from '../assets/powerbi.png'
import looker from '../assets/looker.png'


import airtable from '../assets/airtable.png'
import coda from '../assets/coda.png'
import { IoIosArrowDown } from "react-icons/io";
import {BsCheckCircle, BsCloudCheck, BsCodeSlash, BsMouse, BsGraphUp, BsBarChartLine  } from "react-icons/bs";
import integrations from '../assets/biggerapps.png'


function ReportHow() {
    return (
        <div className = "heroIntegrationsSection ">
           <h4>Whatever's Right for You</h4>
           
           <div className = "integrationsHowSection">
            <div className = "reportHowBox">
            <div className = "">
            <BsGraphUp size={40}/>
                <h2 className = "">Simplified Export and Reports</h2>
                </div>
                <p>Our simplified reports can help you collect and analyze data points from Commerce7, including exporting to whatever data tool you use most.</p>
                <div className = "appIconGrid">
                <img className = "appIcon" src={excel}/>
                <img className = "appIcon" src={googlesheets}/>
                <img className = "appIcon" src={smartsheet}/>
                <img className = "appIcon" src={jotform}/>
                
                </div>
            </div>

            

            <div className = "reportHowBox">
            <div className = "">
            <BsBarChartLine size={40}/>
                <h2 className = "">Advanced Data Analytics</h2>
                </div>
                <p>We can handle more robust data analytics depending on your needs, including building custom dashboard and functionality within Commerce7 or your favourite tool.</p>
                <div className = "appIconGrid">
                <img className = "appIcon" src={powerbi}/>
                <img className = "appIcon" src={tableau}/>
                <img className = "appIcon" src={zoho}/>
                
                </div>
            </div>

            </div>
            <h6>Contact for Pricing</h6>


</div>




    );
}

export default ReportHow;