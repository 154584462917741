import React from 'react';
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import {BsArrowRightShort  } from "react-icons/bs";
import bounce from '../assets/g53.svg';
import winery from '../assets/winery.jpg';
import swirl from '../assets/swirl.jpg'
import aaltaa from '../assets/g172.png';
import zapierlogo from "../assets/zapierlogo.jpeg"
import {BsCheckCircle, BsCloudCheck, BsCodeSlash, BsMouse  } from "react-icons/bs";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {TbArrowDown, TbArrowRight} from "react-icons/tb";

function AppsGrid2() {
    return (
        <div className = "mt50">
        
        



        <motion.div className = "herobgImage linGradBlue">

<div className = "featureSectionflip">

        <div className = "featureLeft">
        <h2 className= "white">Bounced Email Report</h2>
        <p className = "white">The bounced email report helps you quickly see which customers in your CRM have emails that have bounced.
<br></br><br></br>
You can see the customer names, email, status, and easily navigate to their CRM record.</p>
<a className="btn whiteButton" href="#">
        View in Commerce7 <TbArrowRight/>
        </a>

        <div>
       
        </div>
        </div>
        <div className = "featureRight">
        <img className = "imgApp" src = {bounce}></img>
        </div>
        
        </div>
       
</motion.div>

<motion.div className = "herobgImage linGradOrange">

<div className = "featureSectionflip">

        <div className = "featureLeft">
        <h2 className= "white">AALTAA Dashboard</h2>
        <p className = "white">
                A simplified daily dashboard that gives you a clear picture of your Commerce7 performance. 
<br></br><br></br>
Choose different date ranges, and get see your main KPI's.</p>
<a className="btn whiteButton" href="#">
        View in Commerce7 <TbArrowRight/>
        </a>
        <div>
       
        </div>
        </div>
        <div className = "featureRight" style={{borderRadius:'10px'}}>  
        <img className = "imgApp" src = {aaltaa}></img>
        </div>
        
        </div>
       
</motion.div>

<motion.div className = "herobgImage zapbeige">

<div className = "featureSectionflip">

        <div className = "featureLeft">
        <h2 className= "">Automation with Zapier</h2>
        <p className = "">Easily get started with automating and integrating your workflows in Commerce7.
<br></br><br></br>
Zapier has over 5000+ pre built integrations, helping you get up and running quickly.</p>
<a className="btn blackButton" href="#">
        View in Commerce7 <TbArrowRight/>
        </a>
        <div>
       
        </div>
        </div>
        <div className = "featureRight" style={{borderRadius:'10px'}}>  
        <img className = "imgApp" src = {zapierlogo}></img>
        </div>
        
        </div>
       
</motion.div>



        
        
        
        </div>
        


    );
}

export default AppsGrid2;