import React from 'react';
import Navbar from '../components/navbar';
import ReportHero from '../components/reportHero';
import ReportFeature from '../components/reportFeature';
import ReportHow from '../components/reportHow';
import AppCTA from '../components/appCTA';
import Footer from '../components/footer';
import {useEffect} from 'react';
function Reports() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0});
      }, []);


    return (
        <div>
        <Navbar/>
        <ReportHero/>
        <ReportFeature/>
        <ReportHow/>
        <AppCTA/>
        <Footer/>
        </div>
    );
}

export default Reports;