import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import logo from '../assets/newlogo.svg';
import diologo from '../assets/diologo.png';
import {GrMail} from "react-icons/gr";


function Navbar() {
  return (
    <div className = "navBar">
      
    <div className = "navLogo">
    <Link className="navLink" to="/">
<img class = "mainLogo hideWhenSmall" src = {logo}/>
<img class = "iconLogo hideWhenLarge" src = {diologo}/>
</Link>
    </div>

       <div className="navLinks">
          
       
          <Link className="navLink" to="/apps">Apps</Link>
        
          <Link className="navLink" to="/integrations">Integrations</Link>
        
          <Link className="navLink" to="/reports">Reports</Link>
          </div>

          <div className = "navContact">
<a href = "mailto:beattynathan@icloud.com" className = "btn blackButton hideWhenSmall">Contact Us</a>

<a href = "mailto:beattynathan@icloud.com" className = "btn blackButton hideWhenLarge mailButtonSmall"><GrMail size = {15}/></a>

          </div>
        
    </div>
  );
}

export default Navbar;