import React from 'react';
import winery from '../assets/winecollage.png'
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import {TbArrowDown} from "react-icons/tb";


function Hero2() {
    return (
        <div>
            
        <motion.div initial={{ opacity: 0,y:50 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.5
            
        }} className = "heroSection">

        <motion.div initial={{ opacity: 0,y:50 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.55
            
        }} className = "heroLeft">
        <h6 style = {{color:"rgb(0, 119, 255)"}} className = "mbNO">Dionysus Analytics</h6>
        <h1 className = "heroTitle noMT">Next-Gen Winery Solutions</h1>
        <p>Elevate your winery to new heights with our range of prebuilt apps, seamless integration and automation, and advanced reporting. Let us help you take your winery to the next level.</p>
        </motion.div>

        <motion.div initial={{ opacity: 0.5,y:60 }}
        animate={{ opacity: 1, y:0 }} transition={{type: "spring",duration: 2.5, bounce: 0.5 }} className = "heroRight">  
        <div className = "heroImg br12" style={{backgroundImage: `url(${winery})`}}></div>
        </motion.div>
        
        </motion.div>

<motion.div initial={{ opacity: 0 }}
        animate={{ opacity: 1 }} transition={{
            type: "spring",
            duration: 6, bounce: 0.5, delay:1,
            
        }} className = "alignSideDiv mt50">
<h5>How We Help </h5><TbArrowDown/>
</motion.div>




</div>
    );
}

export default Hero2;