import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import Apps from './pages/apps'
import Integrations from './pages/integrations';
import Reports from './pages/reports';
import Zapdocs from './pages/zapdocs';

function App() {
  return (
    <div>
    <BrowserRouter>
<Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/apps" element={<Apps/>}/>
    <Route path="/integrations" element={<Integrations/>}/>
    <Route path="/reports" element={<Reports/>}/>
    <Route path="/zapierdocs" element={<Zapdocs/>}/>
    </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
