import React from 'react';
import winery from '../assets/whitebacksimple.png'
import { motion } from "framer-motion";

import { IoIosArrowDown } from "react-icons/io";
import {BsArrowRightShort  } from "react-icons/bs";
function AppCTA() {
    return (
        <div className = "bgImage"  >


        <div className = "CTASection">

        <motion.div initial={{ opacity: 0,y:50 }}
        animate={{ opacity: 1, y:0 }} transition={{
            type: "spring",
            duration: 2, bounce: 0.5
            
        }} className = "CTAText">
        <h2>Looking for something else?</h2>
        <p className = "noMT">We're always open to hearing about new problems to understand how we can support you better.</p>
        
        <a href = "mailto:beattynathan@icloud.com" className = "CTAbtn blueButton">Let's Talk</a>
        </motion.div>
        
        </div>


</div>
    );
}

export default AppCTA;