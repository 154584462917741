import React from 'react';
import {useEffect} from 'react';
import Navbar from '../components/navbar';
import AppsHero from '../components/appsHero';
import AppsGrid2 from '../components/appsGrid2';
import AppCTA from '../components/appCTA';
import Footer from '../components/footer';

function Apps() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0});
      }, []);

    return (
        <div>
        <Navbar/>
        <AppsHero/>
        <AppsGrid2/>
        <AppCTA/>
        <Footer/>
        </div>
    );
}

export default Apps;