import React from 'react';
import winery from '../assets/winery.jpg'
import { motion } from "framer-motion";

import { IoIosArrowDown } from "react-icons/io";
import {BsCheckCircle, BsCloudCheck, BsCodeSlash, BsMouse  } from "react-icons/bs";
import integrations from '../assets/biggerapps.png'


function IntegrationsHow() {
    return (
        <div className = "heroIntegrationsSection ">
           <h4>What we offer</h4>
           <div className = "integrationsHowSection">
            <div className = "integrationsHowBox">
            <div className = "">
            <BsMouse size={40}/>
                <h2 className = "">Action Based</h2>
                </div>
                <p>Automate actions across Commerce7 to simplify your workflows and save time.</p>
            </div>

            <div className = "integrationsHowBox">
            <div className = "">
            <BsCloudCheck size={40}/>
                <h2 className = "">Cloud Data Sync</h2>
                </div>
                <p>Streamlined data syncing across your apps from Commerce7.</p>
            </div>

            <div className = "integrationsHowBox">
            <div className = "">
            <BsCodeSlash size={40}/>
                <h2 className = "">Added Functionality</h2>
                </div>
                <p>Custom integrations that are tailored to make your life easier with Commerce7. </p>
            </div>

            </div>
<h6>Contact for Pricing</h6>

</div>




    );
}

export default IntegrationsHow;